import {forwardRef} from 'react';
import useTranslation from 'next-translate/useTranslation';

import useTracking from '@/hooks/useTracking';

const FoodProgram = forwardRef<HTMLElement, unknown>(function FoodProgram(_props, ref) {
  const {t} = useTranslation();
  const {trackClick} = useTracking();

  const {firstHeader, firstList, secondHeader, secondList, thirdHeader, thirdList, title} = t<
    Record<string, string>
  >('home-contentful:buildAFoodProgram.fields', {}, {returnObjects: true});

  const {
    corporateSolutionsImageAvifFile,
    corporateSolutionsImageWebpFile,
    corporateSolutionsImageJpgFile,
  } = t<Record<string, {fields: {file: {url: string}}}>>(
    'home-contentful:corporateSolutionsImage.fields',
    {},
    {returnObjects: true},
  );

  return (
    <section
      ref={ref}
      className="m-6 flex max-w-[1538px] flex-col items-center justify-between gap-14 tablet:m-20 tablet:mb-24 tablet:flex-row-reverse tablet:items-start tablet:gap-20 min-[1600px]:mx-auto"
    >
      <div className="flex h-[200px] max-w-[528px] items-end justify-center overflow-hidden rounded-2xl tablet:h-[unset] tablet:items-start">
        <picture>
          <source srcSet={corporateSolutionsImageAvifFile.fields.file.url} type="image/avif" />
          <source srcSet={corporateSolutionsImageWebpFile.fields.file.url} type="image/webp" />
          <source srcSet={corporateSolutionsImageJpgFile.fields.file.url} type="image/jpeg" />
          <img
            alt=""
            className="object-[0_24px] tablet:object-[0]"
            decoding="async"
            height={700}
            loading="lazy"
            src={corporateSolutionsImageJpgFile.fields.file.url}
            width={700}
          />
        </picture>
      </div>

      <div className="text-center tablet:text-left">
        <h2 className="mb-12 max-w-[800px] text-4xl font-extrabold text-kale-green tablet:mb-11 tablet:mt-4 min-[1140px]:text-6xl">
          {title}
        </h2>
        <div className="tablet:mb-5">
          <h3 className="text-2xl font-semibold min-[1140px]:text-3xl min-[1140px]:font-medium">
            {firstHeader}
          </h3>
          <p className="m-0 text-lg font-medium min-[1140px]:text-xl min-[1140px]:font-normal">
            {firstList}
          </p>
        </div>
        <hr className="mx-auto mb-5 mt-7 inline-block h-px w-12 bg-gray-250 tablet:hidden" />
        <div className="tablet:mb-5">
          <h3 className="text-2xl font-semibold min-[1140px]:text-3xl min-[1140px]:font-medium">
            {secondHeader}
          </h3>
          <p className="m-0 text-lg font-medium min-[1140px]:text-xl min-[1140px]:font-normal">
            {secondList}
          </p>
        </div>
        <hr className="mx-auto mb-5 mt-7 inline-block h-px w-12 bg-gray-250 tablet:hidden" />
        <div className="tablet:mb-5">
          <h3 className="text-2xl font-semibold min-[1140px]:text-3xl min-[1140px]:font-medium">
            {thirdHeader}
          </h3>
          <p className="m-0 text-lg font-medium min-[1140px]:text-xl min-[1140px]:font-normal">
            {thirdList}
          </p>
        </div>

        <a
          className="mt-10 inline-block rounded bg-guava-300 p-4 font-bold text-white hover:bg-guava-400 hover:text-white tablet:mt-6"
          href="https://www.ezcater.com/company/corporate-solutions/recurring-employee-meals/"
          onClick={() => {
            trackClick('show-me-how-relish');
          }}
        >
          {t('home:learnMoreCta')}
        </a>
      </div>
    </section>
  );
});

export default FoodProgram;
