import {type FC, forwardRef} from 'react';
import useTranslation from 'next-translate/useTranslation';

import useTracking from '@/hooks/useTracking';
import {COMPANY_EZ_REWARDS_PATH} from '@/paths';
import {compilePath} from '@/utils';
import ContentfulImage from './ContentfulImage';
import {type ContentfulImage as ContentfulImageType} from './types';

type FoodThatWorksPartProps = {
  image: ContentfulImageType;
  header: string;
  body: string;
};

const FoodThatWorksPart: FC<FoodThatWorksPartProps> = ({image, header, body}) => (
  <div className="mx-auto max-w-[390px]">
    <div className="relative m-auto h-36 w-36">
      <ContentfulImage image={image} />
    </div>

    <div>
      <div className="mb-3 text-2xl font-semibold min-[1140px]:text-3xl min-[1140px]:font-medium">
        {header}
      </div>

      <div className="text-lg">{body}</div>
    </div>
  </div>
);

type FoodThatWorksFields = {
  arrowImage: ContentfulImageType;
  firstImage: ContentfulImageType;
  secondImage: ContentfulImageType;
  thirdImage: ContentfulImageType;
} & Record<string, string>;

const FoodThatWorks = forwardRef<HTMLElement, unknown>(function FoodThatWorks(_props, ref) {
  const {t} = useTranslation();
  const {trackClick} = useTracking();

  const {
    arrowImage,
    bottomLink,
    firstBody,
    firstBottomText,
    firstHeader,
    firstImage,
    secondBodyLeftSideText,
    secondBodyRightSideText,
    secondBottomText,
    secondHeader,
    secondImage,
    thirdBody,
    thirdHeader,
    thirdImage,
    title,
  } = t<FoodThatWorksFields>(
    'home-contentful:youNeedFoodThatWorks.fields',
    {},
    {returnObjects: true},
  );

  return (
    <section
      ref={ref}
      className="max-w-[1538px] bg-gray-120 p-6 tablet:m-14 tablet:rounded-2xl tablet:p-12 min-[1600px]:mx-auto"
    >
      <div className="mb-16 mt-4 text-center text-4xl font-extrabold text-ezgreen-700 tablet:mt-6 min-[1140px]:text-6xl">
        {title}
      </div>

      <div className="flex flex-col justify-center gap-16 text-center tablet:flex-row tablet:gap-14 tablet:text-left">
        <FoodThatWorksPart image={firstImage} header={firstHeader} body={firstBody} />
        <FoodThatWorksPart
          image={secondImage}
          header={secondHeader}
          body={`${secondBodyLeftSideText} ${t('home:totalCaterers')} ${secondBodyRightSideText}`}
        />
        <FoodThatWorksPart image={thirdImage} header={thirdHeader} body={thirdBody} />
      </div>

      <div className="mx-auto mt-16 max-w-[850px] rounded-2xl bg-white px-2.5 py-6 text-center tablet:mt-20 tablet:p-6">
        <div className="mb-3 text-lg font-extrabold min-[1140px]:text-2xl">
          <a
            className="text-ezgreen-700 hover:underline"
            href={compilePath(COMPANY_EZ_REWARDS_PATH)}
            onClick={() => {
              trackClick('ez-rewards');
            }}
          >
            {bottomLink}
            <ContentfulImage image={arrowImage} className="ml-2 inline" />
          </a>
        </div>

        <div>
          <div className="mb-2 text-sm font-medium min-[1140px]:text-base">{firstBottomText}</div>
          <div className="text-xs font-medium text-peppercorn-500 min-[1140px]:text-sm">
            {secondBottomText}{' '}
            <a href="http://www.amazon.com/gc-legal" target="_blank" rel="noreferrer">
              {t('home:amazonLegal')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
});

export default FoodThatWorks;
