import {forwardRef} from 'react';
import Script from 'next/script';
import useTranslation from 'next-translate/useTranslation';

type Props = {
  enableTrustPilot: boolean;
};

const CustomersLoveUs = forwardRef<HTMLElement, Props>(function CustomersLoveUs(
  {enableTrustPilot},
  ref,
) {
  const {t} = useTranslation('home-contentful');

  return (
    <section
      ref={ref}
      className="flex max-w-[1538px] flex-col p-6 tablet:m-14 tablet:rounded-2xl tablet:px-12 tablet:py-4 min-[1600px]:mx-auto"
    >
      <div className="order-1 mb-10 mt-10 text-center text-4xl font-extrabold text-ezgreen-700 tablet:text-6xl">
        {t('reviewsTitle')}
      </div>

      <div className="order-4 mb-10 gap-16 text-center tablet:order-2 tablet:flex-row tablet:gap-14 tablet:text-left">
        <div className="w-full">
          {enableTrustPilot && (
            <>
              <div
                className="trustpilot-widget"
                data-businessunit-id="53d960de0000640005792c4a"
                data-locale="en-US"
                data-review-languages="en"
                data-style-height="240px"
                data-style-width="100%"
                data-tags="SelectedReview"
                data-template-id="54ad5defc6454f065c28af8b"
                data-theme="light"
              >
                <a
                  href="https://www.trustpilot.com/review/www.ezcater.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trustpilot
                </a>
              </div>
              <Script
                src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js"
                strategy="lazyOnload"
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
});

export default CustomersLoveUs;
