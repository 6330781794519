import {type FC, useMemo} from 'react';
import {Waypoint} from 'react-waypoint';

import AppBar from '@/components/AppBar';
import ContactUsModal from '@/components/ContactUsModal';
import CookieMessages from '@/components/CookieMessages';
import DirectConnectNotificationsProvider from '@/components/DirectConnectNotificationsProvider';
import SystemMessage from '@/components/SystemMessage';
import useTracking from '@/hooks/useTracking';
import MobileCartProvider from '@/pageComponents/catering-menu/MobileCartProvider';
import CustomersLoveUs from './CustomersLoveUs';
import FoodForWorkMadeEasy from './FoodForWorkMadeEasy';
import FoodProgram from './FoodProgram';
import FoodThatWorks from './FoodThatWorks';
import GrowAndManage from './GrowAndManage';
import Hero from './Hero';
import HomeFooter from './HomeFooter';
import PartnerLogos from './PartnerLogos';

const TOP_OFFSET = '20%';

export type HomeRootProps = {
  lastSearchedAddress?: string | null;
  enableTrustPilot?: boolean;
};

type ScrollableSection =
  | 'home-start-order-address-search-bar'
  | 'build-a-food-program-section'
  | 'logos-section'
  | 'food-that-works-section'
  | 'customers-love-us-section'
  | 'food-for-work-made-easy-section'
  | 'grow-and-manage-section';

const HomeRoot: FC<HomeRootProps> = ({lastSearchedAddress, enableTrustPilot = false}) => {
  const {track} = useTracking();
  const trackedMap = useMemo(() => new Map<string, boolean>(), []);

  const trackComponentView = (section: ScrollableSection) => {
    if (trackedMap.has(section)) {
      return;
    }

    track(`${section}-scroll` as const, {
      category: 'page',
      event: `Scrolled to ${section} on homepage index page`,
      page: 'homepage index',
      type: 'scroll',
      url: window.location.href,
    });

    trackedMap.set(section, true);
  };

  return (
    <MobileCartProvider>
      <DirectConnectNotificationsProvider>
        <AppBar landingPage />
        <ContactUsModal />
        <SystemMessage shouldShowTitle={false} />
        <CookieMessages />
        <Waypoint
          onEnter={() => trackComponentView('home-start-order-address-search-bar')}
          topOffset={TOP_OFFSET}
        >
          <Hero lastSearchedAddress={lastSearchedAddress} />
        </Waypoint>
        <main className="text-peppercorn-800">
          <Waypoint
            onEnter={() => trackComponentView('build-a-food-program-section')}
            topOffset={TOP_OFFSET}
          >
            <FoodProgram />
          </Waypoint>
          <Waypoint onEnter={() => trackComponentView('logos-section')} topOffset={TOP_OFFSET}>
            <PartnerLogos />
          </Waypoint>
          <Waypoint
            onEnter={() => trackComponentView('food-that-works-section')}
            topOffset={TOP_OFFSET}
          >
            <FoodThatWorks />
          </Waypoint>
          <Waypoint
            onEnter={() => trackComponentView('customers-love-us-section')}
            topOffset={TOP_OFFSET}
          >
            <CustomersLoveUs enableTrustPilot={enableTrustPilot} />
          </Waypoint>
          <Waypoint
            onEnter={() => trackComponentView('food-for-work-made-easy-section')}
            topOffset={TOP_OFFSET}
          >
            <FoodForWorkMadeEasy />
          </Waypoint>
          <Waypoint
            onEnter={() => trackComponentView('grow-and-manage-section')}
            topOffset={TOP_OFFSET}
          >
            <GrowAndManage />
          </Waypoint>
        </main>
        <HomeFooter />
      </DirectConnectNotificationsProvider>
    </MobileCartProvider>
  );
};

export default HomeRoot;
