import type React from 'react';
import useTranslation from 'next-translate/useTranslation';

import CloseIcon from '@/assets/icons/close-icon.svg?react';
import {Banner, Content, DismissButton} from './GlobalBanner.styles';

// See BACKGROUND_COLORS for more details
export enum FlashMessageType {
  Confirm = 'confirm',
  Error = 'error',
  Info = 'info',
  Notice = 'notice',
  System = 'system',
  Warning = 'warning',
}

export type GlobalBannerProps = React.PropsWithChildren<{
  messageType: string;
  onDismiss: () => void;
}>;

const GlobalBanner: React.FC<GlobalBannerProps> = ({
  messageType,
  onDismiss = () => {},
  children,
}) => {
  const {t} = useTranslation('common');

  return (
    <Banner role="banner" messageType={messageType}>
      <Content>
        {children}
        <DismissButton data-dismiss="banner" onClick={onDismiss}>
          <CloseIcon aria-label={t('components.GlobalBanner.close')} />
        </DismissButton>
      </Content>
    </Banner>
  );
};

export default GlobalBanner;
