import {
  type ComponentProps,
  type FC,
  forwardRef,
  type MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Autocomplete} from '@mui/material';
import {debounce} from '@mui/material/utils';
import {useField, useFormikContext} from 'formik';

import {type GeocodedAddressResult} from '@/graphql/types';
import PoweredByGoogle from '../GoogleAutocomplete/PoweredByGoogle';
import AddressAutocompleteInput from './AddressAutocompleteInput';
import AddressAutocompleteOption from './AddressAutocompleteOption';

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

const autocompleteService: MutableRefObject<google.maps.places.AutocompleteService | null> = {
  current: null,
};

const AddressAutocompleteListBox = forwardRef<HTMLUListElement, ComponentProps<'ul'>>(
  function AddressAutocompleteListBox({children, ...props}, ref) {
    return (
      <ul {...props} ref={ref}>
        {children}
        <li className="mr-4 flex justify-end">
          <PoweredByGoogle />
        </li>
      </ul>
    );
  },
);

type AddressAutocompleteProps = {
  geocodedAddressResultName: string;
  googlePlaceName: string;
  inputName: string;
  isStandardFormInput?: boolean;
  placeholder?: string;
  shouldSubmitOnSelect?: boolean;
  id: string;
  onFocus?: () => any;
};

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  geocodedAddressResultName,
  googlePlaceName,
  inputName,
  isStandardFormInput,
  placeholder,
  shouldSubmitOnSelect = true,
  onFocus,
  id,
  ...props
}) => {
  const [, inputMeta, inputHelpers] = useField<string>(inputName);
  const [, , geocodedAddressResultHelpers] = useField<GeocodedAddressResult | null>(
    geocodedAddressResultName,
  );
  const [, googlePlaceMeta, googlePlaceHelpers] = useField<AutocompletePrediction | null>(
    googlePlaceName,
  );
  const {value} = googlePlaceMeta;
  const {value: inputValue} = inputMeta;
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);
  const {submitForm} = useFormikContext();

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: {input: string; componentRestrictions: {country: 'us'}},
          callback: (results?: AutocompletePrediction[] | null) => void,
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        400,
      ),
    [],
  );

  useEffect(() => {
    if (!autocompleteService.current && window.google?.maps?.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return undefined;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let active = true;
    fetch({input: inputValue, componentRestrictions: {country: 'us'}}, results => {
      if (active) {
        let newOptions: AutocompletePrediction[] = [];
        if (value && !results?.some(result => result.place_id === value.place_id))
          newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete<AutocompletePrediction, false, false, true>
      filterOptions={x => x}
      filterSelectedOptions
      freeSolo
      fullWidth
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      getOptionKey={option => (typeof option === 'string' ? option : option.place_id)}
      includeInputInList
      ListboxComponent={AddressAutocompleteListBox}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') return;
        if (newValue) setOptions([newValue, ...options]);
        googlePlaceHelpers.setValue(newValue);
        if (shouldSubmitOnSelect) submitForm();
      }}
      onInputChange={(_event, newInputValue) => {
        inputHelpers.setValue(newInputValue);
        if (value?.description !== newInputValue) {
          googlePlaceHelpers.setValue(null);
        }
        if (inputValue !== newInputValue) {
          geocodedAddressResultHelpers.setValue(null);
        }
      }}
      options={options}
      renderInput={renderInputProps => (
        <AddressAutocompleteInput
          {...renderInputProps}
          isStandardFormInput={isStandardFormInput}
          placeholder={placeholder}
          onFocus={onFocus}
          setGeocodedAddressResult={geocodedAddress => {
            geocodedAddressResultHelpers.setValue(geocodedAddress);
          }}
          setInputValue={(addressString: string) => {
            if (addressString) inputHelpers.setValue(addressString);
          }}
        />
      )}
      renderOption={(optionProps, option) => (
        <AddressAutocompleteOption {...optionProps} key={option.place_id} option={option} />
      )}
      inputValue={inputValue}
      value={value}
      id={id}
      {...props}
    />
  );
};

export default AddressAutocomplete;
