import {type FC} from 'react';
import {EzLogo} from '@ezcater/recipe';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {SocialLinks, TrackedLinks, TranscendButton} from '@/components/MarketplaceFooter';
import useMediaQuery from '@/hooks/useMediaQuery';
import ScrollingLink from '@/pageComponents/catering-menu/ScrollingLink';
import {
  ABOUT_US_PATH,
  ACCESSIBILITY_PATH,
  BLOG_PATH,
  CA_NOTICE_OF_COLLECTION_PATH,
  CAREERS_PATH,
  CATERER_PARTNERS_PATH,
  COMPANY_AFFILIATE_PROGRAM,
  COMPANY_STRATEGIC_PARTNERSHIPS,
  CONTACT_PATH,
  CORPORATE_SOLUTIONS_PATH,
  FAQ_PATH,
  PRIVACY_PATH,
  SECURITY_PATH,
  TERMS_PATH,
} from '@/paths';

const COMPANY_NAV_LINKS = [
  {key: 'aboutUs', path: ABOUT_US_PATH},
  {key: 'careers', path: CAREERS_PATH},
  {key: 'strategicPartnerships', path: COMPANY_STRATEGIC_PARTNERSHIPS},
  {key: 'affiliateProgram', path: COMPANY_AFFILIATE_PROGRAM},
  {key: 'blog', path: BLOG_PATH},
];

const INFO_NAV_LINKS = [
  {key: 'contactUs', path: CONTACT_PATH},
  {key: 'faq', path: FAQ_PATH},
  {key: 'security', path: SECURITY_PATH},
];

const GET_STARTED_NAV_LINKS = [
  {key: 'becomeACaterer', path: CATERER_PARTNERS_PATH},
  {key: 'corporateSolutions', path: CORPORATE_SOLUTIONS_PATH},
];

const GET_STARTED_SCROLL_LINKS = [
  {label: 'Start an Order', to: 'start-an-order', toMobile: 'start-an-order-mobile', offset: -300},
];

const STATES_DIRECTORY_LINKS = [
  {label: 'Alabama', href: '/service_areas/al'},
  {label: 'Alaska', href: '/service_areas/ak'},
  {label: 'Arizona', href: '/service_areas/az'},
  {label: 'Arkansas', href: '/service_areas/ar'},
  {label: 'California', href: '/service_areas/ca'},
  {label: 'Colorado', href: '/service_areas/co'},
  {label: 'Connecticut', href: '/service_areas/ct'},
  {label: 'Delaware', href: '/service_areas/de'},
  // DC isnt a state, so linking to search page directly from here
  {label: 'District of Columbia', href: '/catering/dc/washington'},
  {label: 'Florida', href: '/service_areas/fl'},
  {label: 'Georgia', href: '/service_areas/ga'},
  {label: 'Hawaii', href: '/service_areas/hi'},
  {label: 'Idaho', href: '/service_areas/id'},
  {label: 'Illinois', href: '/service_areas/il'},
  {label: 'Indiana', href: '/service_areas/in'},
  {label: 'Iowa', href: '/service_areas/ia'},
  {label: 'Kansas', href: '/service_areas/ks'},
  {label: 'Kentucky', href: '/service_areas/ky'},
  {label: 'Louisiana', href: '/service_areas/la'},
  {label: 'Maine', href: '/service_areas/me'},
  {label: 'Maryland', href: '/service_areas/md'},
  {label: 'Massachusetts', href: '/service_areas/ma'},
  {label: 'Michigan', href: '/service_areas/mi'},
  {label: 'Minnesota', href: '/service_areas/mn'},
  {label: 'Mississippi', href: '/service_areas/ms'},
  {label: 'Missouri', href: '/service_areas/mo'},
  {label: 'Montana', href: '/service_areas/mt'},
  {label: 'Nebraska', href: '/service_areas/ne'},
  {label: 'Nevada', href: '/service_areas/nv'},
  {label: 'New Hampshire', href: '/service_areas/nh'},
  {label: 'New Jersey', href: '/service_areas/nj'},
  {label: 'New Mexico', href: '/service_areas/nm'},
  {label: 'New York', href: '/service_areas/ny'},
  {label: 'North Carolina', href: '/service_areas/nc'},
  {label: 'North Dakota', href: '/service_areas/nd'},
  {label: 'Ohio', href: '/service_areas/oh'},
  {label: 'Oklahoma', href: '/service_areas/ok'},
  {label: 'Oregon', href: '/service_areas/or'},
  {label: 'Pennsylvania', href: '/service_areas/pa'},
  {label: 'Rhode Island', href: '/service_areas/ri'},
  {label: 'South Carolina', href: '/service_areas/sc'},
  {label: 'South Dakota', href: '/service_areas/sd'},
  {label: 'Tennessee', href: '/service_areas/tn'},
  {label: 'Texas', href: '/service_areas/tx'},
  {label: 'Utah', href: '/service_areas/ut'},
  {label: 'Vermont', href: '/service_areas/vt'},
  {label: 'Virginia', href: '/service_areas/va'},
  {label: 'Washington', href: '/service_areas/wa'},
  {label: 'West Virginia', href: '/service_areas/wv'},
  {label: 'Wisconsin', href: '/service_areas/wi'},
  {label: 'Wyoming', href: '/service_areas/wy'},
];

const TOP_BRANDS_DIRECTORY_LINKS = [
  {label: "Dickey's Barbecue Pit", href: '/brand/dickeys-barbecue-pit'},
  {label: "McAlister's Deli", href: '/brand/mcalisters-deli'},
  {label: "Moe's Southwest Grill", href: '/brand/moes-southwest-grill'},
  {label: 'On The Border', href: '/brand/on-the-border'},
  {label: 'Panda Express', href: '/brand/panda-express'},
  {label: 'Potbelly Sandwich Shop', href: '/brand/potbelly-sandwich-works'},
  {label: "Togo's Sandwiches", href: '/brand/togos-sandwiches'},
];

const FOOD_TYPES_DIRECTORY_LINKS = [
  {label: 'Taco', href: '/delivery/taco-catering'},
  {label: 'Cookie', href: '/delivery/cookie-catering'},
  {label: 'BBQ', href: '/delivery/bbq-catering'},
  {label: 'Donuts', href: '/delivery/donut-catering'},
  {label: 'Breakfast', href: '/delivery/breakfast-catering'},
  {label: 'Mexican', href: '/delivery/mexican-catering'},
  {label: 'Pizza', href: '/delivery/pizza-catering'},
  {label: 'Italian', href: '/delivery/italian-catering'},
  {label: 'Sandwich', href: '/delivery/sandwich-catering'},
  {label: 'Bagels', href: '/delivery/bagels-catering'},
  {label: 'Sushi', href: '/delivery/sushi-catering'},
  {label: 'Chinese', href: '/delivery/chinese-catering'},
  {label: 'Ice Cream', href: '/delivery/ice-cream-catering'},
  {label: 'Kosher', href: '/delivery/kosher-catering'},
  {label: 'Coffee', href: '/delivery/coffee-catering'},
  {label: 'Boxed Lunch', href: '/delivery/boxed-lunch-catering'},
  {label: 'Indian', href: '/delivery/indian-catering'},
  {label: 'Mediterranean', href: '/delivery/mediterranean-catering'},
  {label: 'Seafood', href: '/delivery/seafood-catering'},
];

const COLOPHON_LINKS = [
  {key: 'privacy', path: PRIVACY_PATH},
  {key: 'terms', path: TERMS_PATH},
  {key: 'caNoticeAtCollection', path: CA_NOTICE_OF_COLLECTION_PATH},
  {key: 'accessibility', path: ACCESSIBILITY_PATH},
];

type HomeFooterScrollLink = {
  label: string;
  offset?: number;
  to: string;
  toMobile?: string;
};

type HomeFooterNavLink = {
  key: string;
  path: string;
};

type HomeFooterContentProps = {
  header?: string;
  navLinks?: HomeFooterNavLink[];
  scrollLinks?: HomeFooterScrollLink[];
};

const HomeFooterContent: FC<HomeFooterContentProps> = ({header, navLinks, scrollLinks}) => {
  const isMobile = useMediaQuery('(max-width: 1139px)');

  return (
    <div className="grow basis-1 min-[900px]:min-w-[180px] min-[900px]:grow-0">
      {header && <h3 className="text-[17px] font-bold text-white">{header}</h3>}
      <ul>
        {scrollLinks?.map(({label, offset, to, toMobile}) => (
          <li key={to}>
            <ScrollingLink
              className="leading-8 text-white transition duration-250 ease-out hover:text-orange-400"
              to={toMobile && isMobile ? toMobile : to}
              offset={offset}
            >
              {label}
            </ScrollingLink>
          </li>
        ))}
        {navLinks && (
          <TrackedLinks
            className="leading-8 text-white transition duration-250 ease-out hover:text-orange-400"
            links={navLinks}
          />
        )}
      </ul>
    </div>
  );
};

type HomeFooterDirectoryLink = {
  label: string;
  href: string;
};

type HomeFooterDirectoryProps = {
  directoryLinks: HomeFooterDirectoryLink[];
  extraSpace?: boolean;
  header: string;
};

const HomeFooterDirectory: FC<HomeFooterDirectoryProps> = ({
  directoryLinks,
  extraSpace,
  header,
}) => (
  <div className="border-t border-peppercorn-200 pb-3 pt-9 min-[900px]:flex min-[900px]:justify-between">
    <h3 className="text-center text-[17px] font-bold text-white min-[900px]:text-left min-[900px]:leading-8">
      {header}
    </h3>
    <div className="mb-6 flex flex-wrap text-center leading-8 min-[900px]:max-w-[720px] min-[900px]:text-left">
      {directoryLinks.map(({label, href}) => (
        <a
          key={href}
          className={twJoin(
            extraSpace && 'min-[900px]:basis-[230px]',
            'basis-1/2 leading-8 text-white transition duration-250 ease-out hover:text-orange-400 tablet:basis-1/3 min-[900px]:basis-[180px]',
          )}
          href={href}
        >
          {label}
        </a>
      ))}
    </div>
  </div>
);

const HomeFooter: FC<unknown> = () => {
  const {t} = useTranslation('home');

  return (
    <footer className="border-t border-gray-130 bg-gray-170">
      <div className="mx-auto max-w-screen-desktop px-6">
        <div className="mb-9 mt-12 text-center min-[900px]:flex min-[900px]:text-left">
          <div className="flex w-full flex-col items-center min-[900px]:w-auto min-[900px]:grow min-[900px]:basis-1 min-[900px]:items-start">
            <h2 className="sr-only">Footer</h2>

            <div className="text-[23px] text-white">
              <div>{t('footerPhraseTop')}</div>
              <div>
                {t('totalCaterers')} {t('footerPhraseBottom')}
              </div>
            </div>

            <SocialLinks className="mt-3 flex gap-2 text-2xl [&_a:hover]:text-gray-140 [&_a]:text-white" />
          </div>

          <div className="mt-12 flex min-[900px]:ml-6 min-[900px]:mt-0">
            <HomeFooterContent header="Company" navLinks={COMPANY_NAV_LINKS} />
            <HomeFooterContent header="Info" navLinks={INFO_NAV_LINKS} />
            <HomeFooterContent
              header="Get Started"
              navLinks={GET_STARTED_NAV_LINKS}
              scrollLinks={GET_STARTED_SCROLL_LINKS}
            />
          </div>
        </div>

        <HomeFooterDirectory header="States" directoryLinks={STATES_DIRECTORY_LINKS} />
        <HomeFooterDirectory
          header="Top Brands"
          directoryLinks={TOP_BRANDS_DIRECTORY_LINKS}
          extraSpace
        />
        <HomeFooterDirectory header="Food Types" directoryLinks={FOOD_TYPES_DIRECTORY_LINKS} />

        <div className="flex flex-col items-center border-t border-peppercorn-200 py-6 text-sm text-gray-140 tablet:flex-row tablet:gap-3">
          <div className="mb-4 flex items-center tablet:mb-0">
            <div className="mr-3 h-[9px]">
              <EzLogo color="white" size="inherit" />
            </div>

            <span className="grow basis-1 self-center">
              &copy; {new Date().getFullYear()} ezCater, Inc.
            </span>
          </div>
          <TrackedLinks
            as="span"
            className="text-gray-140 underline hover:text-white"
            links={COLOPHON_LINKS}
          />
          <TranscendButton className="underline hover:text-white" hideDivider />
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
