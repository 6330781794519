// @ts-strict-ignore
import {type ReactElement} from 'react';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {type GetServerSidePropsContext} from 'next';
import cookie from 'next/dist/compiled/cookie';
import {type TranslationQuery} from 'next-translate';
import getT from 'next-translate/getT';

import GlobalBanner from '@/components/GlobalBanner';
import {FlashMessageType} from '@/components/GlobalBanner/GlobalBanner';

export const STORE_FLASH_MESSAGES = 'store_flash_messages';

type SetCookieMessageProps = {
  ctx?: GetServerSidePropsContext;
  messageKey: string;
  namespace: string;
  type?: FlashMessageType;
  path?: string;
  query?: TranslationQuery;
};

const decodeFlashMessage = pair => {
  const [messageType, semiEncodedMessage] = pair;

  if (semiEncodedMessage) {
    const message = decodeURIComponent(semiEncodedMessage.replace(/\+/g, ' '));

    return {messageType, message};
  }

  return null;
};

const decodeCookie = cookie => {
  const params = cookie.split('&').map(urlParam => urlParam.split('='));

  return params.map(param => decodeFlashMessage(param)).filter(message => message !== null);
};

export const setFlashMessageInCookie = async ({
  ctx,
  messageKey,
  query,
  namespace,
  type = FlashMessageType.Info,
  path = '/',
}: SetCookieMessageProps) => {
  const t = await getT(ctx.locale, namespace);
  const message = `${type}=${t(messageKey, query)}`;

  const rawCookieHeader = ctx.res.getHeader('Set-Cookie');
  const resCookies = rawCookieHeader
    ? Array.isArray(rawCookieHeader)
      ? rawCookieHeader
      : [rawCookieHeader as string]
    : [];
  resCookies.push(cookie.serialize(STORE_FLASH_MESSAGES, message, {path}));
  ctx.res.setHeader('Set-Cookie', resCookies);
};

const CookieMessages = (): ReactElement => {
  const [cookies, , removeCookie] = useCookies([STORE_FLASH_MESSAGES]);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (cookies?.[STORE_FLASH_MESSAGES]) {
      setMessages(decodeCookie(cookies[STORE_FLASH_MESSAGES]));
      removeCookie(STORE_FLASH_MESSAGES, {path: '/'});
    }
  }, [cookies, removeCookie]);

  const onDismiss = ({message: dismissedMessage, messageType: dismissedType}) => {
    const filteredMessages = messages.filter(
      ({messageType, message}) => messageType !== dismissedType && message !== dismissedMessage,
    );
    setMessages(filteredMessages);
  };

  return (
    <>
      {messages.map(({message, messageType}, index) => (
        <GlobalBanner
          key={index}
          messageType={messageType}
          onDismiss={() => onDismiss({message, messageType})}
        >
          {message}
        </GlobalBanner>
      ))}
    </>
  );
};

export default CookieMessages;
