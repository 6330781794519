import {forwardRef} from 'react';
import useTranslation from 'next-translate/useTranslation';

import partnerLogosAvif from '@/assets/images/home/partner_logos.avif';
import partnerLogosPng from '@/assets/images/home/partner_logos.png';
import partnerLogosWebp from '@/assets/images/home/partner_logos.webp';
import partnerLogosStackedAvif from '@/assets/images/home/partner_logos_stacked.avif';
import partnerLogosStackedPng from '@/assets/images/home/partner_logos_stacked.png';
import partnerLogosStackedWebp from '@/assets/images/home/partner_logos_stacked.webp';

const PartnerLogos = forwardRef<HTMLElement, unknown>(function PartnerLogos(_props, ref) {
  const {t} = useTranslation('home');

  return (
    <section
      ref={ref}
      className="my-14 flex max-w-[1538px] flex-col items-center tablet:mb-12 tablet:mt-16 min-[1600px]:mx-auto"
    >
      <h2 className="capitalize tablet:text-2xl tablet:normal-case">
        {t('partnerLogos.intro')}
        <span className="hidden tablet:inline">:</span>
      </h2>

      <div className="mx-9 my-4 max-w-[1140px]">
        <picture>
          <source srcSet={partnerLogosAvif.src} type="image/avif" />
          <source srcSet={partnerLogosWebp.src} type="image/webp" />
          <source srcSet={partnerLogosPng.src} type="image/jpeg" />
          <img
            alt={t('partnerLogos.imageAlt')}
            className="hidden tablet:block"
            decoding="async"
            height={98}
            loading="lazy"
            src={partnerLogosPng.src}
            width={1400}
          />
        </picture>

        <picture>
          <source srcSet={partnerLogosStackedAvif.src} type="image/avif" />
          <source srcSet={partnerLogosStackedWebp.src} type="image/webp" />
          <source srcSet={partnerLogosStackedPng.src} type="image/jpeg" />
          <img
            alt={t('partnerLogos.stackedImageAlt')}
            className="block tablet:hidden"
            decoding="async"
            loading="lazy"
            src={partnerLogosStackedPng.src}
            height={150}
            width={400}
          />
        </picture>
      </div>
    </section>
  );
});

export default PartnerLogos;
