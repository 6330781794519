import {useCookies} from 'react-cookie';
import useTranslation from 'next-translate/useTranslation';

import GlobalBanner from '@/components/GlobalBanner';
import {useSystemMessageQuery} from '@/graphql/types';

export const SYSTEM_MESSAGE_COOKIE = 'dismiss_system_message';

const wasPreviouslyDismissed = ({
  dismissedAt,
  updatedAt,
}: {
  dismissedAt: string | null;
  updatedAt: string;
}) => dismissedAt && Date.parse(updatedAt) < parseInt(dismissedAt, 10);

type SystemMessageProps = {
  shouldShowTitle?: boolean;
};

const SystemMessage: React.FC<SystemMessageProps> = ({shouldShowTitle = true}) => {
  const {t} = useTranslation('common');
  const [cookies, setCookie] = useCookies([SYSTEM_MESSAGE_COOKIE]);
  const {data, error, loading} = useSystemMessageQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error || !data?.systemMessage) return null;

  const {updatedAt, message, linkText, url} = data.systemMessage;
  const dismissedAt = cookies[SYSTEM_MESSAGE_COOKIE];

  if (wasPreviouslyDismissed({dismissedAt, updatedAt})) return null;

  const shouldShowLink = url && linkText;
  const onDismiss = () => {
    setCookie(SYSTEM_MESSAGE_COOKIE, new Date().getTime());
  };

  return (
    <GlobalBanner messageType="system" onDismiss={onDismiss}>
      {shouldShowTitle && (
        <h3 className="mb-4 text-lg font-bold leading-[1.35] text-white">
          {t('components.SystemMessage.title')}
        </h3>
      )}
      {message}
      {shouldShowLink && <a href={url}>{linkText}</a>}
    </GlobalBanner>
  );
};

export default SystemMessage;
