// @ts-strict-ignore
import {useCallback, useEffect, useRef} from 'react';
import isChromatic from 'chromatic/isChromatic';

import {safeSessionStorage} from '@/utils/storage';

export const SEARCH_SCROLL_DEPTH = 'searchScrollDepth';

const useScrollDepth = () => {
  const scrollEl = useRef<HTMLInputElement>(null);

  const setScrollEl = scrollParent => {
    scrollEl.current = scrollParent;
  };

  const getScrollDepth = useCallback(() => {
    const scrollDepth = safeSessionStorage.getItem(SEARCH_SCROLL_DEPTH);
    return scrollDepth ? parseInt(scrollDepth, 10) : null;
  }, []);

  const storeScrollDepth = () => {
    if (!scrollEl.current) return;

    safeSessionStorage.setItem(SEARCH_SCROLL_DEPTH, scrollEl.current.scrollTop.toString());
  };

  const deleteScrollDepth = useCallback(() => {
    const scrollDepth = getScrollDepth();

    if (scrollDepth) {
      safeSessionStorage.removeItem(SEARCH_SCROLL_DEPTH);
    }
  }, [getScrollDepth]);

  const scrollToTop = () => {
    if (scrollEl.current) {
      scrollEl.current.scrollTo({top: 0});
    }
  };

  const scrollToLastScrollDepth = () => {
    const scrollDepth = getScrollDepth();

    if (scrollEl.current && scrollDepth) {
      scrollEl.current.scrollTo({top: scrollDepth});
    }
  };

  const handleRouteChange = useCallback(() => {
    storeScrollDepth();
  }, []);

  /*
    listen for the user leaving the search page. the nextjs router won't detect this because our menu pages are linked via anchor tags, NOT Link elements, so the router doesn't pick up on it
  */
  useEffect(() => {
    if (isChromatic()) return;
    window.addEventListener('beforeunload', handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleRouteChange);
    };
  }, [handleRouteChange]);

  return {storeScrollDepth, deleteScrollDepth, setScrollEl, scrollToLastScrollDepth, scrollToTop};
};

export default useScrollDepth;
