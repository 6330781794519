import {type ComponentProps, type FC} from 'react';
import Image from 'next/image';

import {type ContentfulImage as ContentfulImageType} from './types';

type ContentfulImageProps = Omit<
  ComponentProps<typeof Image>,
  'alt' | 'height' | 'src' | 'width'
> & {
  image: ContentfulImageType;
};

const ContentfulImage: FC<ContentfulImageProps> = ({image, ...props}) => (
  <Image
    alt={image.fields.title}
    height={image.fields.file.details.image.height}
    loading="lazy"
    src={
      image.fields.file.url.startsWith('//')
        ? `https:${image.fields.file.url}`
        : image.fields.file.url
    }
    width={image.fields.file.details.image.width}
    {...props}
  />
);

export default ContentfulImage;
