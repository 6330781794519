import {forwardRef} from 'react';
import useTranslation from 'next-translate/useTranslation';

import foodForWorkAvif from '@/assets/images/home/food_for_work_made_easy.avif';
import foodForWorkPng from '@/assets/images/home/food_for_work_made_easy.png';
import foodForWorkWebp from '@/assets/images/home/food_for_work_made_easy.webp';
import useTracking from '@/hooks/useTracking';
import ContentfulImage from './ContentfulImage';
import {type ContentfulImage as ContentfulImageType} from './types';

type FoodForWorkMadeEasyFields = {
  appleStoreImageIcon: ContentfulImageType;
  appleAppStoreLink: string;
  googlePlayImageIcon: ContentfulImageType;
  googlePlayStoreLink: string;
  qrCodeToDownloadAppDesktopOnly: ContentfulImageType;
} & Record<string, string>;

const FoodForWorkMadeEasy = forwardRef<HTMLElement, unknown>(
  function FoodForWorkMadeEasy(_props, ref) {
    const {t} = useTranslation();
    const {trackClick} = useTracking();

    const {
      appleStoreImageIcon,
      appleAppStoreLink,
      googlePlayImageIcon,
      googlePlayStoreLink,
      qrCodeToDownloadAppDesktopOnly,
    } = t<FoodForWorkMadeEasyFields>(
      'home-contentful:foodForWorkMadeEasy.fields',
      {},
      {returnObjects: true},
    );

    return (
      <section
        ref={ref}
        className="m-6 flex max-w-[1538px] flex-col items-stretch justify-center overflow-hidden rounded-2xl bg-[#0ca767] text-white tablet:m-14 min-[1140px]:mb-12 min-[1140px]:flex-row min-[1600px]:mx-auto"
      >
        <div className="basis-1/2">
          <picture>
            <source srcSet={foodForWorkAvif.src} type="image/avif" />
            <source srcSet={foodForWorkWebp.src} type="image/webp" />
            <source srcSet={foodForWorkPng.src} type="image/png" />
            <img
              alt={t('home:foodForWorkMadeEasyImageAlt')}
              className="w-full min-[1140px]:block min-[1140px]:h-full min-[1140px]:object-cover min-[1140px]:object-left-top"
              decoding="async"
              height={675}
              loading="lazy"
              src={foodForWorkPng.src}
              width={675}
            />
          </picture>
        </div>

        <div className="m-7 mb-10 flex flex-col items-center justify-center min-[1140px]:m-10 min-[1280px]:mx-20 min-[1280px]:my-12">
          <div className="flex flex-col items-start">
            <h2 className="text-4xl font-extrabold min-[1140px]:leading-tight min-[1400px]:text-6xl">
              {t('home-contentful:foodForWorkMadeEasy.fields.title')}
            </h2>

            <p className="mb-8 mt-4 text-xl font-semibold tablet:text-3xl min-[1140px]:mb-12">
              {t(
                'home-contentful:foodForWorkMadeEasy.fields.description.content.0.content.0.value',
              )}
            </p>
          </div>

          <div
            data-testid="desktop-qr-code-section"
            className="hidden items-center gap-8 rounded-2xl bg-ezgreen-400 py-6 pl-8 pr-16 desktop:flex"
          >
            <ContentfulImage image={qrCodeToDownloadAppDesktopOnly} className="h-36 w-36" />
            <div className="flex shrink-0 grow basis-0 flex-col gap-4">
              <p className="text-[27px] font-bold leading-[130%]">
                {t(
                  'home-contentful:foodForWorkMadeEasy.fields.qrCodeSectionOneText.content.0.content.0.value',
                )}
              </p>
              <p className="text-xl font-semibold">
                {t(
                  'home-contentful:foodForWorkMadeEasy.fields.qrCodeSectionTwoText.content.0.content.0.value',
                )}
              </p>
            </div>
          </div>

          <span className="flex desktop:hidden">
            <a
              data-testid="apple-app-store-link"
              aria-label={appleStoreImageIcon.fields.description}
              href={appleAppStoreLink}
              onClick={() => {
                trackClick('download-our-app', {misc_json: JSON.stringify({app: 'apple-ios'})});
              }}
              className="mr-4 min-[1140px]:mr-8"
            >
              <ContentfulImage
                image={appleStoreImageIcon}
                className="h-[45px] w-auto min-[1400px]:h-auto"
              />
            </a>

            <a
              data-testid="google-play-store-link"
              aria-label={googlePlayImageIcon.fields.description}
              href={googlePlayStoreLink}
              onClick={() => {
                trackClick('download-our-app', {
                  misc_json: JSON.stringify({app: 'google-android'}),
                });
              }}
            >
              <ContentfulImage
                image={googlePlayImageIcon}
                className="h-[45px] w-auto min-[1400px]:h-auto"
              />
            </a>
          </span>
        </div>
      </section>
    );
  },
);

export default FoodForWorkMadeEasy;
