import {forwardRef} from 'react';
import {CustomerService, GroupofPeople, Handshake, Taco} from '@ezcater/icons';
import useTranslation from 'next-translate/useTranslation';

import heroAvif from '@/assets/images/home/hero-bg.avif';
import heroJpg from '@/assets/images/home/hero-bg.jpg';
import heroWebp from '@/assets/images/home/hero-bg.webp';
import heroSmAvif from '@/assets/images/home/hero-bg-sm.avif';
import heroSmJpg from '@/assets/images/home/hero-bg-sm.jpg';
import heroSmWebp from '@/assets/images/home/hero-bg-sm.webp';
import StartOrderForm from './StartOrderForm';

const HeroBullet: React.FC<{icon: React.ReactNode; text: string}> = ({icon, text}) => (
  <li className="flex items-center gap-4 text-white tablet:gap-8">
    {icon}
    <div className="font-medium tablet:text-2xl">{text}</div>
  </li>
);

type HeroProps = {
  lastSearchedAddress?: string | null;
};

const Hero = forwardRef<HTMLElement, HeroProps>(function Hero({lastSearchedAddress}, ref) {
  const {t} = useTranslation();
  const {
    searchBoxHeadline,
    searchBoxHeadlineSpan,
    rtbFirstBulletPoint,
    rtbSecondBulletPoint,
    rtbThirdBulletPoint,
    rtbFourthBulletPoint,
  } = t<Record<string, string>>(
    'home-contentful:searchBoxHeadline.fields',
    {},
    {returnObjects: true},
  );

  return (
    <header ref={ref} className="relative overflow-hidden bg-cover bg-no-repeat tablet:h-[576px]">
      <picture>
        <source media="(max-width: 767px)" srcSet={heroSmAvif.src} type="image/avif" />
        <source media="(min-width: 768px)" srcSet={heroAvif.src} type="image/avif" />
        <source media="(max-width: 767px)" srcSet={heroSmWebp.src} type="image/webp" />
        <source media="(min-width: 768px)" srcSet={heroWebp.src} type="image/webp" />
        <source media="(max-width: 767px)" srcSet={heroSmJpg.src} type="image/jpeg" />
        <source media="(min-width: 768px)" srcSet={heroJpg.src} type="image/jpeg" />
        <img
          alt=""
          className="pointer-events-none absolute inset-0 -z-10 h-full w-full object-cover object-left-top tablet:object-none tablet:object-left min-[1650px]:object-cover min-[1650px]:object-top"
          decoding="async"
          height="100%"
          src={heroJpg.src}
          width="100%"
        />
      </picture>

      <div className="flex h-full w-full justify-center min-[1140px]:h-auto min-[1140px]:px-28 min-[1140px]:pt-20">
        <div className="relative w-full max-w-[1538px] pb-0 pt-16 sm:mb-5 tablet:mb-16 tablet:h-full tablet:flex-row tablet:bg-cilantro-green/90 tablet:px-7 tablet:pb-14 tablet:pt-20 min-[1140px]:flex min-[1140px]:rounded-2xl min-[1600px]:mx-auto">
          <div className="tablet:pl-8">
            <h1 className="mb-12 mt-0 flex flex-col whitespace-nowrap text-center text-3xl font-extrabold text-white tablet:mb-8 tablet:pr-4 tablet:text-6xl min-[1140px]:text-left min-[1140px]:text-4xl min-[1280px]:text-6xl">
              <div>{searchBoxHeadline}</div>
              <div>{searchBoxHeadlineSpan}</div>
            </h1>

            <div id="start-an-order" className="mt-4 hidden min-[1140px]:block">
              <StartOrderForm baseId="home-start-order" lastSearchedAddress={lastSearchedAddress} />
            </div>
          </div>

          <div className="px-2 min-[1140px]:pl-8 min-[1140px]:pr-0 min-[1280px]:pl-16 min-[1600px]:pl-40">
            <ul className="flex flex-col gap-4 tablet:my-4 tablet:ml-4">
              <HeroBullet
                icon={
                  <Taco
                    height="1em"
                    width="1em"
                    className="relative top-px flex-none fill-current text-2xl"
                  />
                }
                text={rtbFirstBulletPoint}
              />
              <HeroBullet
                icon={
                  <GroupofPeople
                    height="1em"
                    width="1em"
                    className="relative top-px flex-none fill-current text-2xl"
                  />
                }
                text={rtbSecondBulletPoint}
              />
              <HeroBullet
                icon={
                  <Handshake
                    height="1em"
                    width="1em"
                    className="relative top-px flex-none fill-current text-2xl"
                  />
                }
                text={rtbThirdBulletPoint}
              />
              <HeroBullet
                icon={
                  <CustomerService
                    height="1em"
                    width="1em"
                    className="relative top-px flex-none fill-current text-2xl"
                  />
                }
                text={rtbFourthBulletPoint}
              />
            </ul>
          </div>

          <div id="start-an-order-mobile" className="w-full pt-12 tablet:pt-6 min-[1140px]:hidden">
            <StartOrderForm
              baseId="home-start-order-mobile"
              lastSearchedAddress={lastSearchedAddress}
            />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Hero;
