import type React from 'react';
import {scroller} from 'react-scroll';

const scrollToElement = (id: string, props: any) => {
  scroller.scrollTo(id, {
    smooth: true,
    offset: 0,
    ...props,
  });
};

type ScrollingLinkProps = Omit<React.ComponentProps<'a'>, 'href'> & {
  to: string;
  containerId?: string;
  offset?: number;
};

const ScrollingLink: React.FC<ScrollingLinkProps> = ({
  to,
  containerId,
  offset = 0,
  children,
  onClick,
  ...props
}) => {
  const clickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    scrollToElement(to, {containerId, offset});
    onClick?.(event);
  };

  return (
    <a href={`#${to}`} onClick={clickHandler} {...props}>
      {children}
    </a>
  );
};

export default ScrollingLink;
