import {Icon} from '@ezcater/tapas';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import parse from 'autosuggest-highlight/parse';

type AddressAutocompleteOptionProps = React.ComponentPropsWithoutRef<'li'> & {
  option: google.maps.places.AutocompletePrediction;
};

const AddressAutocompleteOption: React.FC<AddressAutocompleteOptionProps> = ({
  option,
  ...props
}) => {
  const matches = option.structured_formatting.main_text_matched_substrings || [];
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [match.offset, match.offset + match.length]),
  );

  return (
    <li
      {...props}
      className={`${props.className} !min-h-0 !cursor-pointer !border-b !px-3 !py-1 hover:!bg-peppercorn-50`}
    >
      <div className="flex max-w-full items-center gap-1 text-[13px]">
        <Icon icon={faLocationDot} className="flex-none text-gray-140" />

        <div className="flex items-end gap-1 overflow-hidden whitespace-nowrap">
          <span>
            {parts.map((part, index) => (
              <span key={index} className={`${part.highlight ? 'font-extrabold' : 'font-medium'}`}>
                {part.text}
              </span>
            ))}
          </span>

          <div className="mb-px truncate text-[11px] text-peppercorn-500">
            {option.structured_formatting.secondary_text}
          </div>
        </div>
      </div>
    </li>
  );
};

export default AddressAutocompleteOption;
