import {type FC, type ReactNode, useEffect} from 'react';
import {Icon} from '@ezcater/tapas';
import {
  faLocationCrosshairs,
  faLocationDot,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import {type AutocompleteRenderInputParams, Box, TextField} from '@mui/material';
import useTranslation from 'next-translate/useTranslation';

import {type GeocodedAddressResult} from '@/graphql/types';
import useUserGeolocationToAddress from '@/hooks/useUserGeolocationToAddress';
import Ribbon, {RibbonState} from '../EventBar/Ribbon';

type AddressAutocompleteInputProps = AutocompleteRenderInputParams & {
  isStandardFormInput?: boolean;
  placeholder?: string;
  onFocus?: () => any;
  setGeocodedAddressResult: (arg: GeocodedAddressResult) => void;
  setInputValue: (arg: string) => void;
};

const determineEndAdornment = ({
  endAdornment,
  handleClick,
  isLoading,
  showUserGeolocatedError,
  t,
}: {
  endAdornment?: ReactNode;
  handleClick: () => void;
  isLoading: boolean;
  showUserGeolocatedError: boolean;
  t: (key: string) => string;
}): ReactNode => {
  if (endAdornment) return endAdornment;

  return (
    <Box
      px={0.5}
      sx={{
        '&:hover': {cursor: 'pointer'},
      }}
      className="MuiAutocomplete-endAdornment"
      onClick={handleClick}
      data-testid="geolocation-button"
    >
      {showUserGeolocatedError ? (
        <div>
          <Icon icon={faLocationCrosshairs} className="text-red-110" />
          <div className="absolute right-3 top-12 z-10">
            <Ribbon
              state={RibbonState.Error}
              arrowPlacement="up"
              message={
                <>
                  {t('components.prefilledLocation.prefilledLocationErrorLineOne')}
                  <br />
                  {t('components.prefilledLocation.prefilledLocationErrorLineTwo')}
                </>
              }
            />
          </div>
        </div>
      ) : isLoading ? (
        <div>
          <Icon size="xsmall" icon={faSpinnerThird} className="animate-spin" aria-hidden />
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <Icon icon={faLocationCrosshairs} className="text-blue-100" />
      )}
    </Box>
  );
};

const AddressAutocompleteInput: FC<AddressAutocompleteInputProps> = ({
  isStandardFormInput,
  onFocus,
  setGeocodedAddressResult,
  setInputValue,
  ...props
}) => {
  const {t} = useTranslation('common');
  const {
    clearUserGeolocationResults,
    handleGeolocationClick,
    userGeolocatedAddress,
    userGeolocatedAddressString,
    isLoading,
    showUserGeolocatedError,
  } = useUserGeolocationToAddress();

  useEffect(() => {
    if (userGeolocatedAddress && userGeolocatedAddressString) {
      setGeocodedAddressResult(userGeolocatedAddress);
      setInputValue(userGeolocatedAddressString);

      clearUserGeolocationResults();
    }
  }, [
    clearUserGeolocationResults,
    setGeocodedAddressResult,
    setInputValue,
    userGeolocatedAddress,
    userGeolocatedAddressString,
  ]);

  // For use in any form that requires an address
  if (isStandardFormInput) {
    return (
      <div className="rounded border border-gray-140 ring-black focus-within:ring-2">
        <TextField
          {...props}
          aria-label={t('components.AddressAutocomplete.ariaLabel')}
          fullWidth
          InputProps={{
            ...props.InputProps,
            disableUnderline: true,
            startAdornment: (
              <Box fontSize="16px" px={1}>
                <Icon icon={faLocationDot} className="text-red-100" />
              </Box>
            ),
            style: {paddingBottom: '2px', paddingTop: '1px'},
            onMouseDownCapture: event => event.stopPropagation(), // prevents results from opening on focus
          }}
          placeholder={t('components.AddressAutocomplete.addressInputLabel')}
          sx={{
            'input:focus': {boxShadow: 'none'},
            '.MuiAutocomplete-endAdornment': {marginRight: 1},
          }}
          variant="standard"
        />
      </div>
    );
  }

  // For use on the logged out home page
  return (
    <TextField
      {...props}
      fullWidth
      InputProps={{
        ...props.InputProps,
        onFocusCapture: onFocus,
        disableUnderline: true,
        endAdornment: determineEndAdornment({
          endAdornment: props.InputProps.endAdornment,
          handleClick: handleGeolocationClick,
          isLoading,
          showUserGeolocatedError,
          t,
        }),
        startAdornment: (
          <Box fontSize="18px" px={1.5}>
            <Icon icon={faLocationDot} className="text-red-100" />
          </Box>
        ),
        style: {fontSize: '18px', paddingBottom: '7px', paddingTop: '7px'},
      }}
      placeholder={t('components.AddressAutocomplete.addressInputLabel')}
      sx={{
        'input:focus': {boxShadow: 'none'},
        '.MuiAutocomplete-endAdornment': {marginRight: 1},
      }}
      variant="standard"
    />
  );
};

export default AddressAutocompleteInput;
