import {forwardRef} from 'react';
import useTranslation from 'next-translate/useTranslation';

import growAndManageAvif from '@/assets/images/home/grow_and_manage.avif';
import growAndManagePng from '@/assets/images/home/grow_and_manage.png';
import growAndManageWebp from '@/assets/images/home/grow_and_manage.webp';
import useTracking from '@/hooks/useTracking';
import {CATERER_PARTNERS_PATH} from '@/paths';
import {compilePath} from '@/utils';

const GrowAndManage = forwardRef<HTMLElement, unknown>(function GrowAndManage(_props, ref) {
  const {t} = useTranslation();
  const {trackClick} = useTracking();

  return (
    <section
      ref={ref}
      className="m-6 flex max-w-[1538px] flex-col items-stretch justify-center overflow-hidden rounded-2xl bg-yellow-90 tablet:m-14 min-[1140px]:mb-12 min-[1140px]:flex-row min-[1600px]:mx-auto"
    >
      <div className="m-7 mb-10 flex-shrink self-center min-[1140px]:m-10 min-[1280px]:my-12 min-[1280px]:ml-28 min-[1600px]:mr-28">
        <h2 className="text-3xl font-extrabold text-ezgreen-700 tablet:text-4xl min-[1140px]:leading-tight min-[1400px]:text-6xl">
          {t('home-contentful:growAndManage.fields.title')}
        </h2>

        <p className="my-5 text-lg tablet:text-2xl min-[1140px]:my-7">
          {t('home-contentful:growAndManage.fields.description.content.0.content.0.value', {
            count: t('home:totalCaterers'),
          })}
        </p>

        <a
          aria-label={t('home:getInTouchAriaLabel')}
          className="mb-0 mt-2 inline-block rounded bg-guava-300 p-4 font-bold text-white hover:bg-guava-400 hover:text-white min-[1140px]:mt-5"
          href={compilePath(CATERER_PARTNERS_PATH)}
          onClick={() => {
            trackClick('grow-and-manage-cta');
          }}
        >
          {t('home:getInTouchCta')}
        </a>
      </div>

      <div className="flex-shrink-0 min-[1140px]:max-w-[38rem]">
        <picture>
          <source srcSet={growAndManageAvif.src} type="image/avif" />
          <source srcSet={growAndManageWebp.src} type="image/webp" />
          <source srcSet={growAndManagePng.src} type="image/png" />
          <img
            alt={t('home:growAndMangeImageAlt')}
            className="w-full min-[1140px]:block min-[1140px]:h-full min-[1140px]:object-cover min-[1140px]:object-left-top"
            decoding="async"
            height={675}
            loading="lazy"
            src={growAndManagePng.src}
            width={675}
          />
        </picture>
      </div>
    </section>
  );
});

export default GrowAndManage;
