import {useState} from 'react';

import useGeolocation from '@/components/GeolocationProvider/useGeolocation';
import {type GeocodedAddressResult, useGeocodeCoordinatesQueryLazyQuery} from '@/graphql/types';
import useTracking from '@/hooks/useTracking';
import {geocodedAddressResultToString} from '@/utils/geocodedAddressResult';

type UseUserGeolocationType = {
  clearUserGeolocationResults: () => void;
  handleGeolocationClick: () => void;
  userGeolocatedAddress: GeocodedAddressResult | null;
  userGeolocatedAddressString: string | null;
  isLoading: boolean;
  showUserGeolocatedError: boolean;
};

const useUserGeolocationToAddress = (): UseUserGeolocationType => {
  const [userGeolocatedAddress, setUserGeolocatedAddress] = useState<GeocodedAddressResult | null>(
    null,
  );
  const [userGeolocatedAddressString, setUserGeolocatedAddressString] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUserGeolocatedError, setShowUserGeolocatedError] = useState<boolean>(false);
  const [geocodeCoordinatesQuery] = useGeocodeCoordinatesQueryLazyQuery();
  const {track, trackClick} = useTracking();

  const handleGeolocationSuccess = async (position: GeolocationPosition) => {
    setIsLoading(true);

    track('precise-location-granted', {
      page: window.location.pathname,
      url: window.location.href,
    });

    const {latitude, longitude} = position.coords;
    const {data} = await geocodeCoordinatesQuery({
      variables: {latitude, longitude},
    }).finally(() => setIsLoading(false));

    if (data) {
      const firstGeocodedAddressResult = data?.geocodedCoordinates?.[0];

      if (firstGeocodedAddressResult) {
        const addressString = geocodedAddressResultToString(firstGeocodedAddressResult);

        setUserGeolocatedAddress(firstGeocodedAddressResult);
        setUserGeolocatedAddressString(addressString);

        track('precise-location-address-filled', {
          misc_json: JSON.stringify({
            address_string: addressString,
          }),
          page: window.location.pathname,
          url: window.location.href,
        });
      }
    } else {
      setShowUserGeolocatedError(true);
    }
  };

  const handleGeolocationError = (positionError: GeolocationPositionError) => {
    setIsLoading(false);
    setShowUserGeolocatedError(true);

    track('precise-location-denied', {
      misc_json: JSON.stringify({error_message: positionError.message}),
      page: window.location.pathname,
      url: window.location.href,
    });
  };

  const {getCurrentPosition} = useGeolocation();

  const handleGeolocationClick = () => {
    setIsLoading(true);
    setShowUserGeolocatedError(false);
    trackClick('precise-location-target');

    getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
  };

  const clearUserGeolocationResults = () => {
    setUserGeolocatedAddress(null);
    setUserGeolocatedAddressString(null);
  };

  return {
    clearUserGeolocationResults,
    handleGeolocationClick,
    userGeolocatedAddress,
    userGeolocatedAddressString,
    isLoading,
    showUserGeolocatedError,
  };
};

export default useUserGeolocationToAddress;
