// @ts-strict-ignore
import styled from '@emotion/styled';

const BACKGROUND_COLORS = {
  confirm: 'ezGreen',
  info: 'blue800',
  notice: 'yellow600',
  warning: 'orange',
  error: 'redError',
  system: 'blueBright',
};

export const Banner = styled.div<{messageType: string}>`
  position: relative;
  background-color: ${({theme}) => theme.palette.appColors.kaleGreen};
  color: ${({theme}) => theme.palette.appColors.white};

  ${({messageType, theme}) =>
    messageType &&
    `
      background-color: ${theme.palette.appColors[BACKGROUND_COLORS[messageType]]};
    `};
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: ${({theme}) => `${theme.spacing(4)} ${theme.spacing(3)}`};
  padding-right: ${({theme}) => theme.spacing(6)};
  margin: auto;
  max-width: calc(${({theme}) => theme.spacing(20)} * 8);

  > a {
    color: inherit;
    font-weight: ${({theme}) => theme.typography.appFonts.fontWeightExtraBold};
  }
`;

export const DismissButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({theme}) => theme.palette.appColors.white};
  cursor: pointer;
  padding: ${({theme}) => theme.spacing(0.5)};
  position: absolute;
  right: ${({theme}) => theme.spacing(1.5)};
  top: ${({theme}) => theme.spacing(3.5)};
  font-size: ${({theme}) => theme.typography.appFonts.textXl};
`;
