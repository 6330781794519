import {type ReactElement} from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import poweredByGoogleImage from '@/assets/images/powered_by_google_on_white_hdpi.png';

const PoweredByGoogle = (): ReactElement => {
  const {t} = useTranslation('common');
  return (
    <div className="flex">
      <Image
        alt={t('components.EventBar.GoogleAutocomplete.poweredByGoogle')}
        className="mx-auto my-2"
        height="15"
        src={poweredByGoogleImage.src}
        width="120"
      />
    </div>
  );
};

export default PoweredByGoogle;
